import React from "react";
import "../classes/recommendations.css";

import ItemCard from "./ItemCard";
import { snacksData } from "../data/snacksData";

const SnacksCardList = () => {
  return (
    <section id="recommendation" className="recommendation">
      <div className="title">
        <h1>Explore our wide range of Snacks</h1>
      </div>

      <div className="recommendationBox">
        {snacksData.map((item) => {
          const { name, desc, price, quantity, image } = item;
          return (
            <ItemCard
              name={name}
              desc={desc}
              price={price}
              quantity={quantity}
              image={image}
              showBtn={false}
              showOrderCta={true}
            />
          );
        })}
      </div>
    </section>
  );
};

export default SnacksCardList;
