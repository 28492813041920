import React from "react";
import proprietor from "../assets/about/proprietor.png";
import founder from "../assets/about/founder.png";

const About = () => {
  return (
    <div className="w-10/12 mx-auto p-4">
      <header className="text-center mb-8">
        <h1 className="text-4xl font-bold">Sri Mayuri Sweets</h1>
        <p className="text-xl text-gray-500">Since 1980</p>
      </header>
      <section className="space-y-8">
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col items-center sm:flex-row sm:space-x-6">
          <img
            src={founder}
            alt="Founder"
            className="w-32 h-32 rounded-full object-cover mb-4 sm:mb-0"
          />
          <div className="text-center sm:text-left">
            <h2 className="text-2xl font-semibold mb-2">
              Mr. Chintapalli Sivaji
            </h2>
            <p className="text-gray-700 mb-4">
              Welcome to Sri Mayuri Sweets! Established in 1980, our journey has
              been filled with the sweet moments of bringing joy to our
              customers through our delicious sweets. We believe in using the
              finest ingredients to create traditional sweets that resonate with
              the flavors of our heritage.
            </p>
            <p className="font-semibold">— Founder, Sri Mayuri Sweets</p>
          </div>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col items-center sm:flex-row sm:space-x-6">
          <img
            src={proprietor}
            alt="Director"
            className="w-32 h-32 rounded-full object-cover mb-4 sm:mb-0"
          />
          <div className="text-center sm:text-left">
            <h2 className="text-2xl font-semibold mb-2">
              Mr. Chintapalli Srinivas
            </h2>
            <p className="text-gray-700 mb-4">
              At Sri Mayuri Sweets, we are committed to upholding the legacy of
              quality and taste that our founder established. We strive to
              innovate while staying true to our roots, ensuring that every
              sweet we produce brings a smile to your face. Thank you for being
              a part of our sweet journey.
            </p>
            <p className="font-semibold">— Proprietor, Sri Mayuri Sweets</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
