import MadathaKhaja from "../assets/sweets/MadathaKhaja.png";
import Badusha from "../assets/sweets/Badusha.png";
import BellamGavvalu from "../assets/sweets/BellamGavvalu.png";
import DryFruitPootharekulu from "../assets/sweets/DryFruitPootharekulu.png";
import GorimitiPanchadara from "../assets/sweets/GorimitiPanchadara.png";
import Jahangir from "../assets/sweets/Jahangir.png";
import MotichoorLadoo from "../assets/sweets/MotichoorLadoo.png";
import MysorePak from "../assets/sweets/MysorePak.png";
import PanchadaraKommulu from "../assets/sweets/PanchadaraKommulu.png";
import PeddaBoondiAchu from "../assets/sweets/PeddaBoondiAchu.png";
import PeddaBoondiLaddu from "../assets/sweets/PeddaBoondiLaddu.png";
import SunnundaluBellam from "../assets/sweets/SunnundaluBellam.png";
import GottamKhaja from "../assets/sweets/GottamKhaja.png";
import Jelabi from "../assets/sweets/Jelabi.png";
import GulabJamun from "../assets/sweets/GulabJamun.png";
import KalaJamun from "../assets/sweets/KalaJamun.png";
import WhiteRasgulla from "../assets/sweets/WhiteRasgulla.png";
import PinkRasgulla from "../assets/sweets/PinkRasgulla.png";
import WhiteRaskadha from "../assets/sweets/WhiteRaskadha.png";
import DryFruitLadoo from "../assets/sweets/DryFruitLadoo.png";
import GheeMysorePak from "../assets/sweets/GheeMysorePak.png";
import MixedKalakand from "../assets/sweets/MixedKalakand.png";
import BrownKalakand from "../assets/sweets/BrownKalakand.png";
import IceCreamBarfi from "../assets/sweets/IceCreamBarfi.png";
import PalaKova from "../assets/sweets/PalaKova.png";
import DiamondKova from "../assets/sweets/DiamondKova.png";
import SoanPapidi from "../assets/sweets/SoanPapidi.png";
import BesanLadoo from "../assets/sweets/BesanLadoo.png";
import ThakkuduLadoo from "../assets/sweets/ThakkuduLadoo.png";
import Halwa from "../assets/sweets/Halwa.png";
import SugarPoothaRekulu from "../assets/sweets/SugarPoothaRekulu.png";

export const sweetsData = [
  {
    name: "Madatha Khaja",
    desc: "Khaja is basically Layered fritters made of maida flour and dunked in sugar syrup. The layering gives the sweet its desired flakiness. No festival is complete without this mouth-watering sweet in Andhra.",
    price: 200,
    quantity: "1 kg",
    image: MadathaKhaja,
  },
  {
    name:"Kakinada Gottam Khaja",
    desc:"Kakinada Gottam Khaja is a traditional sweet from Kakinada, Andhra Pradesh. Known for its crispy, flaky texture and cylindrical shape, it's made from wheat flour, sugar, and ghee. The dough is deep-fried and soaked in sugar syrup, creating a light, airy, and sweet delicacy cherished by locals and visitors alike.",
    price: 200,
    quantity: "1 kg",
    image: GottamKhaja,
  },
  {
    name: "Badusha",
    desc: "A melt-in-your-mouth Indian sweet, deep-fried to golden perfection, soaked in sugar syrup, and adorned with a sprinkle of pistachios for a delightful treat",
    price: 240,
    quantity: "1 kg",
    image: Badusha,
  },
  {
    name: "Bellam Gavvalu",
    desc: "Crispy, shell-shaped treats made with jaggery, combining the sweet allure of caramelized sugar with the delightful crunch of these traditional South Indian confections",
    price: 200,
    quantity: "1 kg",
    image: BellamGavvalu,
  },
  {
    name: "Dry Fruit Pootharekulu",
    desc: "Delectable confectionary delights, combining the richness of dry fruits with the traditional charm of Pootharekulu",
    price: 800,
    quantity: "1 kg",
    image: DryFruitPootharekulu,
  },
  {
    name:"Sugar Dry Fruit Pootharekulu",
    desc: "Sugar Pootha Rekulu is a traditional Andhra Pradesh dessert consisting of layered rice crepes filled with a mixture of ghee, sugar, and cardamom powder. It's known for its delicate layers and sweet, aromatic filling, making it a favorite during festive celebrations.",
    price: 800,
    quantity: "1 kg",
    image: SugarPoothaRekulu,
  },
  {
    name: "Gorimiti Panchadara",
    desc: "A sweet and savory South Indian delight, showcasing the perfect blend of jaggery sweetness and spicy flavors in a crispy, golden-brown treat",
    price: 240,
    quantity: "1 kg",
    image: GorimitiPanchadara,
  },
  {
    name: "Jahangir",
    desc: "An exquisite Mughlai-inspired sweet, named after the legendary emperor, featuring layers of rich khoya, nuts, and saffron, creating a regal indulgence for the palate",
    price: 260,
    quantity: "1 kg",
    image: Jahangir,
  },
  {
    name: "Motichur Laddu",
    desc: "Miniature golden orbs of sweetness, crafted from fine chickpea flour, fried to perfection, and delicately coated in sugar syrup, offering a classic Indian treat.",
    price: 280,
    quantity: "1 kg",
    image: MotichoorLadoo,
  },
  {
    name: "Mysore Pak",
    desc: "A South Indian delicacy renowned for its crumbly texture and rich flavor, blending ghee, besan (gram flour), and sugar into a delectable confection that melts in the mouth",
    price: 260,
    quantity: "1 kg",
    image: MysorePak,
  },
  {
    name: "Panchadara Kommulu",
    desc: "These sweet delights, named for their fivefold sweetness, feature a harmonious blend of jaggery, coconut, and other flavors, creating a delectable treat with a touch of tradition",
    price: 200,
    quantity: "1 kg",
    image: PanchadaraKommulu,
  },
  {
    name: "Pedda Boondi Achu",
    desc: "Tiny, golden-hued spheres of sweetness, skillfully crafted from besan (gram flour) and adorned with a hint of cardamom, presenting a delightful South Indian confection",
    price: 260,
    quantity: "1 kg",
    image: PeddaBoondiAchu,
  },
  {
    name: "Pedda Boondi Laddu",
    desc: "Irresistible spheres of sweet indulgence, combining tiny boondis with ghee, sugar syrup, and a touch of cardamom, creating a classic and flavorful Indian treat",
    price: 240,
    quantity: "1 kg",
    image: PeddaBoondiLaddu,
  },
  {
    name: "Sunnundalu Bellam",
    desc: "Wholesome and nutritious, these laddus feature roasted urad dal flour combined with jaggery, forming delectable balls that celebrate the richness of tradition and simplicity",
    price: 500,
    quantity: "1 kg",
    image: SunnundaluBellam,
  },
  {
    name:"Jelabi",
    desc:"Jelabi is a South Indian sweet made from jaggery, sugar, and ghee. It's a delicious treat that can be enjoyed in a variety of ways, including as a snack or as a dessert",
    price: 200,
    quantity: "1 kg",
    image: Jelabi,
  },
  {
    name:"Gulab Jamun",
    desc:"Gulab Jamun is a popular Indian dessert made from deep-fried milk solids, soaked in a fragrant sugar syrup flavored with cardamom and rose water. These soft, spongy balls are often enjoyed warm and are a staple at festive occasions and celebrations.",
    price: 280,
    quantity: "1 kg",
    image: GulabJamun,
  },
  {
    name:"Kala Jamun",
    desc:"Kala Jamun is a dark-colored variant of the traditional Indian dessert Gulab Jamun, made from deep-fried milk solids and soaked in a sugar syrup flavored with cardamom and rose water. Its distinct dark hue and slightly denser texture make it a unique and beloved treat at festive occasions.",
    price: 280,
    quantity: "1 kg",
    image: KalaJamun,
  },
  {
    name:"White Rasgulla",
    desc:"White Rasgulla is a classic Indian dessert consisting of soft, spongy balls made from chhena (Indian cottage cheese) and semolina, soaked in a light sugar syrup. Renowned for its delicate texture and mildly sweet flavor, it is a favorite treat across India.",
    price: 280,
    quantity: "1 kg",
    image: WhiteRasgulla
  },
  {
    name:"Pink Rasgulla",
    desc:"Pink Rasgulla is a delightful twist on the classic Indian dessert, featuring soft, spongy balls made from chhena (Indian cottage cheese) and semolina, soaked in a rose-flavored pink sugar syrup. Its vibrant color and fragrant sweetness make it a festive favorite.",
    price: 280,
    quantity: "1 kg",
    image: PinkRasgulla
  },
  {
    name:"White Raskadha",
    desc:"White Raskadha is a rich Indian dessert made from creamy chhena (Indian cottage cheese) and khoya (dried milk), shaped into soft, delicate balls. These are soaked in a mildly sweet, thickened milk syrup, offering a luxurious and indulgent treat.",
    price: 280,
    quantity: "1 kg",
    image: WhiteRaskadha
  },
  {
    name:"Dry Fruit Ladoo",
    desc:"Dry Fruit Ladoo is a nutritious Indian sweet made from a mix of finely chopped nuts, dried fruits, and seeds, bound together with ghee and natural sweeteners like jaggery or dates. These energy-packed treats are both delicious and healthy, often enjoyed during festivals and special occasions.",
    price: 500,
    quantity: "1 kg",
    image: DryFruitLadoo,
  },
  {
    name: "Ghee Mysore Pak",
    desc:"Ghee Mysore Pak is a rich and decadent South Indian sweet made from gram flour, sugar, and an abundance of ghee. Known for its melt-in-the-mouth texture and aromatic flavor, it is a beloved delicacy, especially during festivals and celebrations.",
    price: 500,
    quantity: "1 kg",
    image: GheeMysorePak,
  },
  {
    name:"Mixed Kalakand",
    desc:"Mixed Kalakand is a delightful Indian dessert combining the richness of condensed milk with paneer (Indian cottage cheese), flavored with cardamom and garnished with nuts. Its creamy texture and sweet, aromatic taste make it a popular choice for festive occasions.",
    price: 500,
    quantity: "1 kg",
    image: MixedKalakand,
  },
  {
    name: "Brown Kalakand",
    desc:"Brown Kalakand is a variation of the traditional Indian sweet, made with condensed milk and paneer, cooked until it caramelizes to a rich brown color. Its intense flavor and slightly chewy texture distinguish it as a decadent treat enjoyed on special occasions.",
    price: 500,
    quantity: "1 kg",
    image: BrownKalakand,
  },
  {
    name:"Ice cream Barfi",
    desc:"Ice Cream Barfi is a fusion dessert that combines the creamy richness of traditional Indian barfi with the coolness of ice cream. It offers a delightful blend of flavors and textures, making it a refreshing treat for dessert lovers.",
    price: 500,
    quantity: "1 kg",
    image: IceCreamBarfi,
  },
  {
    name:"Pala Kova",
    desc:"Pala kova is a traditional South Indian sweet made by simmering milk until it reduces to a creamy, fudge-like consistency, often flavored with cardamom and garnished with nuts. Its rich, milky flavor and smooth texture make it a cherished delicacy during festivals and celebrations.",
    price: 400,
    quantity: "1 kg",
    image: PalaKova,
  },
  {
    name: "Diamond Kova",
    desc:"Diamond Kova is a sweet delicacy originating from South India, made by reducing milk until it forms a thick, solid consistency and then cut into diamond-shaped pieces. Known for its creamy texture and rich flavor, it is a popular treat enjoyed during festive occasions.",
    price: 300,
    quantity: "1 kg",
    image: DiamondKova,
  },
  {
    name:"Soan Papidi",
    desc:"Soan Papdi is a popular Indian dessert known for its flaky, melt-in-your-mouth texture and sweet flavor, often flavored with cardamom and garnished with almonds or pistachios. It is enjoyed as a light and crispy treat during festivals and celebrations.",
    price: 260,
    quantity: "1 kg",
    image: SoanPapidi,
  },
  {
    name: "Besan Ladoo",
    desc:"Besan Ladoo is a traditional Indian sweet made from roasted gram flour (besan), ghee, and sugar, shaped into round balls and garnished with nuts. These aromatic and mildly sweet treats are a favorite at festivals and special occasions.",
    price: 260,
    quantity: "1 kg",
    image: BesanLadoo,
  },
  {
    name : "Thakkudu Ladoo",
    desc:"Thakkudu Ladoo is a popular South Indian sweet made from roasted gram flour (besan), ghee, and jaggery, molded into small, round balls. Known for its rich flavor and dense texture, it's a cherished treat often enjoyed during festivities and celebrations.",
    price: 280,
    quantity: "1 kg",
    image: ThakkuduLadoo,
  },
  {
    name: "Halwa",
    desc:"Halwa is a sweet and dense dessert found across various cultures, made from ingredients like semolina (rava), carrots, or wheat flour, cooked with ghee, sugar, and often garnished with nuts or dried fruits. Its soft, pudding-like consistency and aromatic flavors make it a beloved treat for special occasions.",
    price: 260,
    quantity: "1 kg",
    image: Halwa,
  }
];
