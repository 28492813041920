import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import About from "./pages/About";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Sweets from "./pages/Sweets";
import Snacks from "./pages/Snacks";
import Terms from "./pages/Terms";
import Location from "./pages/Location";

const Approuter = () => {
  return (
    <main className="mt-28 h-screen mb-8 p-1 md:p-4 lg:p-8 overflow-y-scroll">
      <Router>
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/sweets" element={<Sweets />} />
          <Route path="/snacks" element={<Snacks />} />
          <Route path="/locate" element={<Location/>} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </main>
  );
};

export default Approuter;
