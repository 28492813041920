import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-10/12 mx-auto p-4">
      <header className="text-center mb-8">
        <h1 className="text-3xl font-bold">Privacy Policy</h1>
      </header>
      <section className="space-y-8">
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
          <p className="text-gray-700">
            At Sri Mayuri Sweets, we value your privacy and are committed to
            protecting your personal data. This Privacy Policy explains how we
            collect, use, disclose, and safeguard your information when you
            visit our website.
          </p>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">
            Information We Collect
          </h2>
          <p className="text-gray-700">
            We may collect personal information that you provide to us, such as
            your name, email address, and phone number when you contact us or
            make a purchase. We also collect data automatically when you visit
            our website, including your IP address, browser type, and pages you
            visit.
          </p>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">
            How We Use Your Information
          </h2>
          <p className="text-gray-700">
            We use the information we collect to provide, operate, and maintain
            our website and services, improve and personalize your experience,
            communicate with you, and comply with legal obligations.
          </p>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">
            Sharing Your Information
          </h2>
          <p className="text-gray-700">
            We do not sell, trade, or otherwise transfer your personal
            information to outside parties except as described in this policy.
            We may share information with trusted service providers who assist
            us in operating our website, conducting our business, or serving our
            users, so long as those parties agree to keep this information
            confidential.
          </p>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">Your Rights</h2>
          <p className="text-gray-700">
            You have the right to access, correct, or delete your personal data.
            You can also object to or restrict our processing of your data and
            request the transfer of your data to another service provider.
          </p>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">
            Changes to This Privacy Policy
          </h2>
          <p className="text-gray-700">
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            You are advised to review this Privacy Policy periodically for any
            changes.
          </p>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p className="text-gray-700">
            If you have any questions about this Privacy Policy, please contact
            us at: srinivaschintapalli1996@gmail.com.
          </p>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
