import React from "react";
import { Typography, Button } from "@material-tailwind/react";
import { useNavigate } from "react-router";

const HomeCarouselCard = ({ title, desc, image, imageDesc, path }) => {
  const navigate = useNavigate();
  const handlePathClk = () => {
    navigate(path);
  };
  return (
    <div className="relative h-96 w-full">
      <img src={image} alt={imageDesc} className="h-96 w-full object-cover" />
      <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/60 p-1.5">
        <div className="w-3/4 text-center md:w-2/4">
          <Typography
            variant="h1"
            color="white"
            className="mb-4 text-xl md:text-3xl lg:text-5xl"
          >
            {title}
          </Typography>
          <Typography
            variant="lead"
            color="white"
            className="mb-6 md:mb-12 lg:mb-12 opacity-80 text-sm md:text-lg lg:text-lg"
          >
            {desc}
          </Typography>
          <div className="flex justify-center gap-2">
            <Button size="md" color="white" onClick={handlePathClk}>
              Know more
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCarouselCard;
