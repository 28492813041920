import React from "react";
import "../classes/recommendations.css";

import { recommendedSnacksData } from "../data/recommendationsData";
import ItemCard from "./ItemCard";

const RecommendationSnacks = () => {
  return (
    <section id="recommendation" className="recommendation">
      <div className="title">
        <h1>Our Featured Snacks</h1>
      </div>

      <div className="recommendationBox">
        {recommendedSnacksData.map((item) => {
          const { name, desc, price, quantity, image } = item;
          return (
            <ItemCard
              name={name}
              desc={desc}
              price={price}
              quantity={quantity}
              image={image}
              showBtn={true}
              btnTxt="View All"
              path="/snacks"
            />
          );
        })}
      </div>
    </section>
  );
};

export default RecommendationSnacks;
