import React from "react";
import { Carousel } from "@material-tailwind/react";

import { homeCarouselData } from "../data/homeCarouselData";
import HomeCarouselCard from "./HomeCarouselCard";

const HomeCarousel = () => {
  return (
    <Carousel className="rounded-xl">
      {homeCarouselData.map((section) => {
        const { id, title, desc, image, imageDesc, path } = section;
        return (
          <HomeCarouselCard
            title={title}
            desc={desc}
            image={image}
            imageDesc={imageDesc}
            path={path}
            key={id}
          />
        );
      })}
    </Carousel>
  );
};

export default HomeCarousel;
