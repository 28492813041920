import React from "react";
import HomeCarousel from "../components/HomeCarousel";
import Testimonials from "../components/Testimonals";
import Recommendations from "../components/Recommendations";
import RecommendationSnacks from "../components/RecommendationSnacks";

const Home = () => {
  return (
    <div className="bg-white">
      <HomeCarousel />
      <Recommendations />
      <RecommendationSnacks />
      <Testimonials />
    </div>
  );
};

export default Home;
