import React from "react";

const Footer = () => {
  return (
    <footer className="relative bg-blueGray-200 pt-8 pb-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap text-left lg:text-left">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl fonat-semibold text-blueGray-700">Visit us!</h4>
            <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
              We are now open from 9:00 AM to 10:00 PM in the following address
            </h5>
            <div className="mt-6 lg:mb-0 mb-6">
              <p className="text-lg mt-0 mb-2 text-blueGray-600">
                2-62, Sri Mayuri Sweets, <br />
                Opp. Sri Musallamma thalli temple, <br />
                Indrapalem, Kakinada -6. <br />
                Phone:{" "}
                <a href="tel:+919912671999" className="underline">
                  +91 9912671999
                </a>{" "}
                ,{" "}
                <a href="tel:+919542449889" className="underline">
                  +91 9542449889
                </a>
                <br />
              </p>
            </div>
          </div>
          <div className="w-full lg:w-6/12">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-blueGray-800 text-sm font-bold mb-2">
                  Useful Links
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      href="/about"
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      href="/terms"
                    >
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      href="privacy-policy"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      href="/locate"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-blueGray-300" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-blueGray-500 font-semibold py-1">
              Copyright © <span id="get-current-year">2024</span>
              <a href="/" className="text-blueGray-500 hover:text-gray-800">
                {" "}
                Sri Mayuri Sweets
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
