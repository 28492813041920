import Mixture from "../assets/snacks/Mixture.png";
import KaraBoondi from "../assets/snacks/KaraBoondi.png";
import AgraMixture from "../assets/snacks/AgraMixture.png";
import SpecialMixture from "../assets/snacks/SpecialMixture.png";
import KaraPoosa from "../assets/snacks/KaraPoosa.png";
import Biscuits from "../assets/snacks/Biscuits.png";
import VammuLinkilu from "../assets/snacks/VammuLinkilu.png";
import MasalaPeanuts from "../assets/snacks/MasalaPeanuts.png";
import MasalaCashew from "../assets/snacks/MasalaCashew.png";
import BadamGheer from "../assets/snacks/BadamGheer.png";
import Samosa from "../assets/snacks/Samosa.png";

export const snacksData = [
  {
    name: "Mixture",
    desc: "Mixture is a popular South Indian snack made from a blend of crunchy ingredients like fried lentils, peanuts, curry leaves, and spices. It's known for its savory and spicy flavor, perfect for enjoying with tea or as a quick snack.",
    price: 240,
    quantity: "1 kg",
    image: Mixture,
  },
  {
    name: "Kara Boondi",
    desc: "Kara Boondi is a crispy South Indian snack made from chickpea flour (besan), spices, and fried to a golden brown. Its savory, crunchy texture and spicy seasoning make it a favorite accompaniment with tea or as a snack on its own.",
    price: 240,
    quantity: "1 kg",
    image: KaraBoondi,
  },
  {
    name: "Agra Mixture",
    desc: "Agra Mixture is a savory Indian snack known for its blend of crispy ingredients like chickpea flour noodles, peanuts, and spices. It offers a delightful crunch and spicy flavor, perfect for snacking or serving during gatherings.",
    price: 240,
    quantity: "1 kg",
    image: AgraMixture,
  },
  {
    name: "Special Mixture",
    desc: "Special Mixture is a traditional Indian snack featuring a mix of crispy ingredients such as fried lentils, peanuts, sev (fried gram flour noodles), and spices. Its savory and spicy flavors make it a popular choice for tea-time snacking or festive occasions.",
    price: 240,
    quantity: "1 kg",
    image: SpecialMixture,
  },
  {
    name: "Kara Poosa",
    desc: "Kara Poosa is a savory South Indian snack made from rice flour and spices, deep-fried to a crispy texture. Known for its spicy flavor and crunchy bite, it's a popular choice for tea-time or as a crunchy snack.",
    price: 240,
    quantity: "1 kg",
    image: KaraPoosa,
  },
  {
    name: "Biscuits",
    desc: "Half Moon Biscuits are delicate Indian pastries filled with sweetened coconut, nuts, and spices, shaped into crescent moons and baked to a golden brown. These treats are enjoyed for their crispy exterior and flavorful filling, often served during festive gatherings.",
    price: 240,
    quantity: "1 kg",
    image: Biscuits,
  },
  {
    name:"Vammu Linkilu",
    desc:"Linkilu is a traditional South Indian snack made from a mix of rice flour, sesame seeds, cumin seeds, and spices, deep-fried to a crisp texture. These savory, bite-sized treats are popular for their crunchy exterior and flavorful seasoning.",
    price: 240,
    quantity: "1 kg",
    image: VammuLinkilu,
  },
  {
    name:"Masala Peanuts",
    desc:"Masala Peanuts are a popular South Indian snack made from spiced peanuts, deep-fried to a crispy texture. Known for its spicy flavor and crunchy bite, they're a popular choice for tea-time snacking or festive occasions.",
    price: 240,
    quantity: "1 kg",
    image: MasalaPeanuts,
  },
  {
    name:"Masala Cashew",
    desc:"Masala Cashew is a traditional South Indian snack made from cashew nuts and spices, deep-fried to a crispy texture. Known for its spicy flavor and crunchy bite, it's a popular choice for tea-time snacking or festive occasions.",
    price: 1200,
    quantity: "1 kg",
    image: MasalaCashew,
  },
  {
    name:"Badam Mik",
    desc:"Badam Milk is a rich and creamy Indian beverage made from milk, ground almonds (badam), sugar, and flavored with cardamom. It's served hot or cold and is cherished for its nutty flavor and nourishing qualities.",
    price: 50,
    quantity: "300 ml",
    image: BadamGheer,
  },
  {
    name:"Samosa",
    desc:"Samosa is a popular Indian snack consisting of a crispy, triangular pastry filled with spiced potatoes, peas, and sometimes minced meat or lentils. It's enjoyed both as a street food and at home, often served with chutney or yogurt dip.",
    price: 10,
    quantity: "1 Piece",
    image: Samosa,
  }
];
