import React from "react";
import Classes from "../classes/testimonals.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

const TestimonalCard = ({ content, author }) => {
  return (
    <div className={Classes.testimonialBox}>
      <FontAwesomeIcon icon={faQuoteLeft} className={Classes.Icon} />
      <p>{content}</p>

      <div className={Classes.info}>
        <div>
          <h3>{author}</h3>
        </div>
      </div>
    </div>
  );
};

export default TestimonalCard;
