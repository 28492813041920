import React from "react";

import Classes from "../classes/testimonals.module.css";
import TestimonalCard from "./TestimonalCard";
import { testimonalData } from "../data/testimonalData";

const Testimonials = () => {
  return (
    <section id="testimonials" className={Classes.testimonials}>
      <h1>Happy Customers</h1>

      <div className={Classes.boxContainer}>
        {testimonalData.map((testimonal) => {
          const { content, author } = testimonal;
          return <TestimonalCard content={content} author={author} />;
        })}
      </div>
    </section>
  );
};

export default Testimonials;
