import React from "react";
import { Typography } from "@material-tailwind/react";

const Contact = () => {
  return (
    <div
    className="grid justify-items-center items-center"
    >
      <Typography variant="h4" >
        Please give a missed call to{" "}
        <a href="tel:+919912671999" className="bg-gray-500 text-blue-100">
          +91 - 99 126 71 999
        </a>{" "}
        to get a call back from us.
      </Typography>
    </div>
  );
};

export default Contact;
