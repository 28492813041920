import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const CallOrderButton = () => {
  return (
    <a href="tel:+919912671999">
      <button className="bg-green-900 hover:bg-green-500 text-white font-bold py-1 px-2 rounded-md">
        <FontAwesomeIcon icon={faPhone} />  Order Now
      </button>
    </a>
  );
};

export default CallOrderButton;
