import MadathaKhaja from "../assets/sweets/MadathaKhaja.png";
import Badusha from "../assets/sweets/Badusha.png";
import BellamGavvalu from "../assets/sweets/BellamGavvalu.png";

// Snacks
import Mixture from "../assets/snacks/Mixture.png";
import KaraBoondi from "../assets/snacks/KaraBoondi.png";
import AgraMixture from "../assets/snacks/AgraMixture.png";

export const recommendedsweetsData = [
  {
    name: "Madatha Khaja",
    desc: "Khaja is basically Layered fritters made of maida flour and dunked in sugar syrup. The layering gives the sweet its desired flakiness. No festival is complete without this mouth-watering sweet in Andhra.",
    price: 200,
    quantity: "1 kg",
    image: MadathaKhaja,
  },
  {
    name: "Badusha",
    desc: "A melt-in-your-mouth Indian sweet, deep-fried to golden perfection, soaked in sugar syrup, and adorned with a sprinkle of pistachios for a delightful treat",
    price: 240,
    quantity: "1 kg",
    image: Badusha,
  },
  {
    name: "Bellam Gavvalu",
    desc: "Crispy, shell-shaped treats made with jaggery, combining the sweet allure of caramelized sugar with the delightful crunch of these traditional South Indian confections",
    price: 200,
    quantity: "1 kg",
    image: BellamGavvalu,
  },
];

export const recommendedSnacksData = [
  {
    name: "Mixture",
    desc: "Mixture is a popular South Indian snack made from a blend of crunchy ingredients like fried lentils, peanuts, curry leaves, and spices. It's known for its savory and spicy flavor, perfect for enjoying with tea or as a quick snack.",
    price: 240,
    quantity: "1 kg",
    image: Mixture,
  },
  {
    name: "Kara Boondi",
    desc: "Kara Boondi is a crispy South Indian snack made from chickpea flour (besan), spices, and fried to a golden brown. Its savory, crunchy texture and spicy seasoning make it a favorite accompaniment with tea or as a snack on its own.",
    price: 240,
    quantity: "1 kg",
    image: KaraBoondi,
  },
  {
    name: "Agra Mixture",
    desc: "Agra Mixture is a savory Indian snack known for its blend of crispy ingredients like chickpea flour noodles, peanuts, and spices. It offers a delightful crunch and spicy flavor, perfect for snacking or serving during gatherings.",
    price: 240,
    quantity: "1 kg",
    image: AgraMixture,
  }
]
