import Approuter from "./Approuter";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { ThemeProvider } from "@material-tailwind/react";

function App() {
  return (
    <ThemeProvider>
      <Header />
      <Approuter />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
