import React from "react";
import { Button } from "@material-tailwind/react";

import "../classes/recommendations.css";
import { useNavigate } from "react-router";
import CallOrderButton from "./CallOrderButton";

const ItemCard = ({
  name,
  desc,
  price,
  quantity,
  image,
  showBtn,
  btnTxt,
  path,
  showOrderCta,
}) => {
  const navigate = useNavigate();
  const handleClk = () => {
    navigate(path);
  };
  return (
    <div className="box">
      <div className="image">
        <img src={image} alt={name} />
      </div>
      <h3>{name}</h3>
      <p>{desc}</p>

      <div className="price">
        <p>{`Price: Rs. ${price} / ${quantity}`}</p>
        {showBtn && <Button onClick={handleClk}>{btnTxt}</Button>}
        {showOrderCta && <CallOrderButton />}
      </div>
    </div>
  );
};

export default ItemCard;
