import section1 from "../assets/homeCarousel/section1.png";
import section2 from "../assets/homeCarousel/section2.png";
export const homeCarouselData = [
  {
    id: 1,
    title: "True Deliciousness and legacy in making sweets",
    desc: "With over 50 years of legacy and deliciousness in making sweets, we are truly committed to the customer satisfaction.",
    image: section1,
    imageDesc: "about-section",
    path: "/about",
  },
  {
    id: 2,
    title: "Wide Range of Delicious Sweets",
    desc: "Explore our wide range of deliciouse and quality sweets at very affordable prices.",
    image: section2,
    imageDesc: "sweets-section",
    path: "/sweets",
  },
];
