export const testimonalData = [
    {
        "content":"Good quality eatables at affordable price and hygiene place.. Very much underrated. !!",
        "author":"Nithin Kumar"
    },
    {
        "content":"I gave five star rating because every thing was very Tasty because of pure ghee...",
        "author":"Mahima George"
    },
    {
        "content":"Popular sweets are available here.  Good service and packaging is good",
        "author":"Sravani Bangaram"
    }
]