import React from "react";

const Terms = () => {
  return (
    <div className="max-w-10/12 mx-auto p-4">
      <header className="text-center mb-8">
        <h1 className="text-3xl font-bold">Terms and Conditions</h1>
      </header>
      <section className="space-y-8">
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
          <p className="text-gray-700">
            These Terms and Conditions govern your use of our website. By
            accessing or using our site, you agree to be bound by these Terms
            and Conditions and our Privacy Policy.
          </p>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">2. Use of the Site</h2>
          <p className="text-gray-700">
            You agree to use our site for lawful purposes only. You must not use
            our site in any way that breaches any applicable local, national, or
            international law or regulation.
          </p>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">
            3. Intellectual Property
          </h2>
          <p className="text-gray-700">
            All content on this site, including text, graphics, logos, and
            images, is the property of Sri Mayuri Sweets or its content
            suppliers and is protected by international copyright laws.
          </p>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">
            4. Limitation of Liability
          </h2>
          <p className="text-gray-700">
            In no event shall Sri Mayuri Sweets be liable for any damages
            arising out of or in connection with your use of this site. This
            limitation of liability applies to all damages of any kind.
          </p>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">
            5. Changes to These Terms
          </h2>
          <p className="text-gray-700">
            We reserve the right to modify these Terms and Conditions at any
            time. Any changes will be posted on this page. It is your
            responsibility to review these Terms and Conditions periodically for
            updates.
          </p>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">6. Contact Us</h2>
          <p className="text-gray-700">
            If you have any questions about these Terms and Conditions, please
            contact us at: srinivaschintapalli1996@gmail.com.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Terms;
