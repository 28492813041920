import React from "react";

export default function Location() {
  return (
    <>
      <h4 className="text-3xl fonat-bold text-blueGray-800 text-center pb-4">
        Contact Us
      </h4>
      <div className="container px-4 lg:flex lg:flex-row lg:space-x-8">
        <div className="lg:basis-1/2 w-full">
          <h5 className="text-md font-bold lg:text-lg mt-0 mb-2 text-blueGray-600">
            We are now open from 9:00 AM to 10:00 PM in the following address:
          </h5>
          <div className="mt-6 lg:mb-0 mb-6">
            <p className="text-md lg:text-lg mt-0 mb-2 text-blueGray-600">
              2-62, Sri Mayuri Sweets, <br />
              Opp. Sri Musallamma thalli temple, <br />
              Indrapalem, Kakinada -6, <br />
              Kakinada Disrict, Andhra Pradesh <br />
              Pincode: 533006.
              <br />
              Phone:{" "}
              <a href="tel:+919912671999" className="underline">
                +91 9912671999
              </a>{" "}
              ,{" "}
              <a href="tel:+919542449889" className="underline">
                +91 9542449889
              </a>
              <br />
            </p>
            <h5 className="text-md font-normal lg:text-lg mt-0 mb-2 text-blue-600">
              Note: We deliver across the Andhra Pradesh and Telangana regions through the RTC.
            </h5>
          </div>
        </div>
        <div className="lg:basis-1/2 w-full h-80">
          <iframe
            title="location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3816.2718451827554!2d82.21693237564342!3d16.961173483855205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a382803a1257021%3A0xe02c6397851a71c!2sSri%20Mayuri%20Sweets!5e0!3m2!1sen!2sin!4v1720325383018!5m2!1sen!2sin"
            style={{
              width: "100%",
              height: "100%",
              border: "1px solid #afafaf",
            }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
    </>
  );
}
